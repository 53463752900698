import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'error-component',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-component.component.html',
  styleUrls: ['./error-component.component.scss']
})
export class ErrorComponent {
  @Output() closeError = new EventEmitter<boolean>();

  public closePopup(data:boolean) {
    this.closeError.emit(data);
  }
  

}
