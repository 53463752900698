<div class="mx-auto mt-8 px-6 md:px-8 lg:px-16 xl:px-30 2xl:px-55" id="service">
  <h1 class="text-3xl leading-tight font-bold uppercase text-slate-700 dark:text-slate-200">
    <span class="text-sky-500">
      What
    </span> we do
  </h1>
  <div class="what_we_do">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide>
        <div class="slide">
          <div class="flex items-center flex-col w-full">
            <div class="basis-full">
              <div class="flex flex-col items-start">
                <h6 class="text-sm/[16px] font-bold text-slate-700 dark:text-slate-200 inline-flex items-baseline">
                  <span
                    class="bg-purple-600 uppercase text-white dark:text-slate-200 inline-flex w-8 h-8 items-center justify-center rounded text-xl mr-1">
                    W</span>eb & Mobile Apps
                </h6>
              </div>
              <div class="flex flex-col items-start mt-6">
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Intuitive design</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Friendliness interface</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Offline Mode</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Admin & Analytics</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Cross Platform</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Scalable</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Modern Tech stack</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Secure Gateway</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="slide">
          <div class="flex items-center flex-col w-full">
            <div class="basis-full">
              <div class="flex flex-col items-start">
                <h6 class="text-sm/[16px] font-bold text-slate-700 dark:text-slate-200 inline-flex items-baseline">
                  <span
                    class="bg-orange-500 uppercase text-white dark:text-slate-200 inline-flex w-8 h-8 items-center justify-center rounded text-xl mr-1">
                    E</span>learn Platform
                </h6>
              </div>
              <div class="flex flex-col items-start mt-6">
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Live Events</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Interactive webinars</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Prerecorded courses</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Quizzes & Models</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Mock interviews</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Certification</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Short answers</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template carouselSlide>
        <div class="slide">
          <div class="flex items-center flex-col w-full">
            <div class="basis-full">
              <div class="flex flex-col items-start">
                <h6 class="text-sm/[16px] font-bold text-slate-700 dark:text-slate-200 inline-flex items-baseline">
                  <span
                    class="bg-yellow-500 uppercase text-white dark:text-slate-200 inline-flex w-8 h-8 items-center justify-center rounded text-xl mr-1">
                    D</span>ev-ops Automation
                </h6>
              </div>
              <div class="flex flex-col items-start mt-6">
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">CI/CD</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Collaboration</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Quick Release</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Stabliization</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Flexibility</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Creativity</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Transparency</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="slide">
          <div class="flex items-center flex-col w-full">
            <div class="basis-full">
              <div class="flex flex-col items-start">
                <h6 class="text-sm/[16px] font-bold text-slate-700 dark:text-slate-200 inline-flex items-baseline">
                  <span
                    class="bg-emerald-500 uppercase text-white dark:text-slate-200 inline-flex w-8 h-8 items-center justify-center rounded text-xl mr-1">
                    S</span>olution Providers
                </h6>
              </div>
              <div class="flex flex-col items-start mt-6">
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Troubleshoot</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Migration</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Upgradation</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Review</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Training </span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Adoption</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>


      <ng-template carouselSlide>
        <div class="slide">
          <div class="flex items-center flex-col w-full">
            <div class="basis-full">
              <div class="flex flex-col items-start">
                <h6 class="text-sm/[16px] font-bold text-slate-700 dark:text-slate-200 inline-flex items-baseline">
                  <span
                    class="bg-rose-500 uppercase text-white dark:text-slate-200 inline-flex w-8 h-8 items-center justify-center rounded text-xl mr-1">
                    AI</span>/Machine Learning
                </h6>
              </div>
              <div class="flex flex-col items-start mt-6">
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Chatbot</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Prediction</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Decision model</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">RPA</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Rule Engine</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Assistants</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Deep learning</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="slide">
          <div class="flex items-center flex-col w-full">
            <div class="basis-full">
              <div class="flex flex-col items-start">
                <h6 class="text-sm/[16px] font-bold text-slate-700 dark:text-slate-200 inline-flex items-baseline">
                  <span
                    class="bg-indigo-500 uppercase text-white dark:text-slate-200 inline-flex w-8 h-8 items-center justify-center rounded text-xl mr-1">
                    T</span>esting automation
                </h6>
              </div>
              <div class="flex flex-col items-start mt-6">
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">99% Bug Free</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Role of AI in QA</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Understanding Requirements</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Test Cases</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Adoptive plugins</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Load test</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Integrated test</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Sanity Test</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="slide">
          <div class="flex items-center flex-col w-full">
            <div class="basis-full">
              <div class="flex flex-col items-start">
                <h6 class="text-sm/[16px] font-bold text-slate-700 dark:text-slate-200 inline-flex items-baseline">
                  <span
                    class="bg-lime-500 uppercase text-white dark:text-slate-200 inline-flex w-8 h-8 items-center justify-center rounded text-xl mr-1">
                    W</span>idgets/plugin
                </h6>
              </div>
              <div class="flex flex-col items-start mt-6">
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Innovation</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Rebuild</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Thoughts</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Expand</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Connecting dots</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Plug and play</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Open source</span>
                </div>
                <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
                  <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
                  <span class="mt-1">Reusable</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </owl-carousel-o>
  </div>
</div>