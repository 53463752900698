<div class="mx-auto mt-8 px-6 md:px-8 lg:px-16 xl:px-30 2xl:px-55">
    <h1 class="text-3xl leading-tight font-bold  text-slate-700 dark:text-slate-200">
        Our
        <span class="text-sky-500">
            Testimonials
        </span>
    </h1>

    <div class="what_we_do">
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="w-full h-full">
                        <div class="h-full rounded flex flex-col justify-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                    class="block w-5 h-5 text-sky-500 mb-4" viewBox="0 0 975.036 975.036">
                                    <path
                                        d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                                    </path>
                                </svg>
                                <p class="leading-relaxed mb-6 text-slate-500 text-sm13 dark:text-slate-400">
                                    I had a fantastic experience with i2Tym. The trainers were incredibly knowledgeable and
                                    supportive throughout the entire course. The curriculum was well-structured, and the
                                    practical exercises helped me gain hands-on experience. Thanks to the training I
                                    received here, I was able to apply my new skills directly in my job, and it has
                                    significantly boosted my career prospects. I highly recommend i2tym to anyone looking to
                                    upskill and excel in their professional journey.
                                </p>
                            </div>
                            <a class="inline-flex items-center">
                                <span
                                    class="w-12 h-12 rounded-full inline-flex text-lg text-white dark:text-slate-200 uppercase font-bold items-center justify-center bg-emerald-500 flex-shrink-0">S</span>
                                <span class="flex-grow flex flex-col pl-4">
                                    <span class="text-xmd text-slate-700 dark:text-slate-200">Selva Kumar M</span>
                                    <span class="text-emerald-500 font-semibold uppercase text-sm">IT
                                        Professional</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="w-full h-full">
                        <div class="h-full rounded flex flex-col justify-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                    class="block w-5 h-5 text-sky-500 mb-4" viewBox="0 0 975.036 975.036">
                                    <path
                                        d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                                    </path>
                                </svg>
                                <p class="leading-relaxed mb-6 text-sm13 text-slate-500 dark:text-slate-400">
                                    As a startup, we needed a website that would not only establish our online presence but
                                    also set us apart from the competition.I2tym delivered beyond our expectations. They
                                    crafted a visually stunning and mobile-responsive website that effectively communicates
                                    our brand story. They were receptive to our ideas and provided valuable
                                    insights to optimize the user experience. Thanks to I2tym, we now have a website that is
                                    driving growth and attracting new customers.
                                </p>
                            </div>
                            <a class="inline-flex items-center">
                                <span
                                    class="w-12 h-12 rounded-full inline-flex text-lg text-white dark:text-slate-200 uppercase font-bold items-center justify-center bg-cyan-500 flex-shrink-0">R</span>
                                <span class="flex-grow flex flex-col pl-4">
                                    <span class="text-xmd text-slate-700 dark:text-slate-200">Ram</span>
                                    <span class="text-cyan-500 font-semibold uppercase text-sm">Medical
                                        Representative</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="w-full h-full">
                        <div class="h-full rounded flex flex-col justify-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                    class="block w-5 h-5 text-sky-500 mb-4" viewBox="0 0 975.036 975.036">
                                    <path
                                        d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                                    </path>
                                </svg>
                                <p class="leading-relaxed mb-6 text-sm13 text-slate-500 dark:text-slate-400">Working with
                                    i2tym was an absolute pleasure. From the initial consultation to the final delivery,
                                    they demonstrated a high level of professionalism and expertise. They took the time to
                                    understand our unique requirements and translated them into a visually stunning and
                                    user-friendly website. The team was responsive and communicative throughout the entire
                                    process, ensuring that our feedback was incorporated effectively. I highly
                                    recommend i2tym to anyone in need of top-notch web development services.</p>
                            </div>
                            <a class="inline-flex items-center">
                                <span
                                    class="w-12 h-12 rounded-full inline-flex text-lg text-white dark:text-slate-200 uppercase font-bold items-center justify-center bg-violet-500 flex-shrink-0">J</span>
                                <span class="flex-grow flex flex-col pl-4">
                                    <span class="text-xmd text-slate-700 dark:text-slate-200">Justin</span>
                                    <span class="text-violet-500 font-semibold uppercase text-sm">Director</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="w-full h-full">
                        <div class="h-full rounded  flex flex-col justify-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                    class="block w-5 h-5 text-sky-500 mb-4" viewBox="0 0 975.036 975.036">
                                    <path
                                        d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                                    </path>
                                </svg>
                                <p class="leading-relaxed mb-6 text-sm13 text-slate-500 dark:text-slate-400">Working with
                                    I2tym has been an absolute game-changer for our organization. Their in-depth knowledge
                                    of software systems and strategic approach to problem-solving have significantly
                                    improved our efficiency and productivity. They took the time to understand our specific
                                    needs and challenges, and their tailored solutions have streamlined our processes and
                                    saved us valuable time and resources. I2tym is a true expert in their field, and we
                                    wholeheartedly recommend their services to any company seeking a top-notch software
                                    consultant.</p>
                            </div>
                            <a class="inline-flex items-center">
                                <span
                                    class="w-12 h-12 rounded-full inline-flex text-lg text-white dark:text-slate-200 uppercase font-bold items-center justify-center bg-fuchsia-500 flex-shrink-0">J</span>
                                <span class="flex-grow flex flex-col pl-4">
                                    <span class="text-xmd text-slate-700 dark:text-slate-200">James</span>
                                    <span class="text-fuchsia-500 font-semibold uppercase text-sm">Technical officer</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="w-full h-full">
                        <div class="h-full rounded flex flex-col justify-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                    class="block w-5 h-5 text-sky-500 mb-4" viewBox="0 0 975.036 975.036">
                                    <path
                                        d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                                    </path>
                                </svg>
                                <p class="leading-relaxed text-sm13 mb-6 text-slate-500 dark:text-slate-400">We were facing
                                    several roadblocks in implementing a new software system until we brought in I2tym.
                                    Their expertise and guidance were instrumental in successfully executing the project.
                                    They provided valuable insights, identified potential pitfalls, and helped us make
                                    informed decisions. Throughout the engagement, I2tym displayed professionalism and
                                    dedication, going above and beyond to ensure we achieved our objectives. We couldn't
                                    have asked for a better software consultant, and we are grateful for their contribution
                                    to our success.</p>
                            </div>
                            <a class="inline-flex items-center">
                                <span
                                    class="w-12 h-12 rounded-full inline-flex text-lg text-white dark:text-slate-200 uppercase font-bold items-center justify-center bg-orange-500 flex-shrink-0">B</span>
                                <span class="flex-grow flex flex-col pl-4">
                                    <span class="text-xmd text-slate-700 dark:text-slate-200">Bijesh</span>
                                    <span class="text-orange-500 font-semibold uppercase text-sm">Staff officer</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="w-full h-full">
                        <div class="h-full rounded flex flex-col justify-between">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                    class="block w-5 h-5 text-sky-500 mb-4" viewBox="0 0 975.036 975.036">
                                    <path
                                        d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                                    </path>
                                </svg>
                                <p class="leading-relaxed text-sm13 mb-6 text-slate-500 dark:text-slate-400">As a startup,
                                    we needed expert guidance to select the right software tools to support our business
                                    operations. I2tym came highly recommended, and they did not disappoint. They patiently
                                    listened to our requirements and recommended the ideal software solutions to align with
                                    our long-term goals. Their implementation process was smooth and efficient, and they
                                    provided comprehensive training to our team, ensuring a seamless transition. Thanks to
                                    I2tym, we have a robust software foundation that can scale as we grow. We are grateful
                                    for their expertise and highly satisfied with the results.</p>
                            </div>
                            <a class="inline-flex items-center">
                                <span
                                    class="w-12 h-12 rounded-full inline-flex text-lg text-white dark:text-slate-200 uppercase font-bold items-center justify-center bg-indigo-500 flex-shrink-0">A</span>
                                <span class="flex-grow flex flex-col pl-4">
                                    <span class="text-xmd text-slate-700 dark:text-slate-200">Ashwath</span>
                                    <span class="text-indigo-500 font-semibold uppercase text-sm">Director</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>