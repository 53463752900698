<div class="mx-auto mt-8 px-6 md:px-8 lg:px-16 xl:px-30 2xl:px-55" id="about">
  <h1 class="text-3xl leading-tight font-bold uppercase text-slate-700 dark:text-slate-200">
    <span class="text-sky-500">
      How
    </span>we Work
  </h1>
  <div class="mt-8 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
    <div class="w-full  flex self-stretch order-1">
      <div
        class="p-3 inline-flex items-start cursor-pointer hover:transition-all text-slate-500 dark:text-slate-400 bg-pageBg dark:bg-dark-pageBg rounded-md w-full relative justify-center">
        <div class="inline-flex flex-col items-center justify-center w-full">
          <button
            class=" p-4 text-white rounded-full absolute z-10 -bottom-[13%] sm:top-[41%] sm:-right-[34px] bg-sky-400 dark:bg-sky-500 inline-flex items-center border-8 border-solid border-white dark:border-dark-1 justify-center w-9 h-9">
            <i class="mdi mdi-arrow-right-bold hidden sm:inline-flex"></i>
            <i class="mdi mdi-arrow-down-bold  sm:hidden inline-flex"></i>
          </button>
          <img src="assets/icons/svg/idea_problem.svg"
            class="max-w-[40%] min-w-[235px] overflow-hidden relative text-center block h-40" alt="">
          <span class="my-4 font-bold text-center text-[12px]/[1.2] capitalize ">Idea / Problem
            <br />Identification</span>
        </div>
        <button
          class="shadow shadow-inner_shadow bg-white dark:bg-dark-1 top-[12px] left-[12px] rounded-full absolute inline-flex items-center justify-center w-4 h-4">
        </button>

      </div>
    </div>

    <div class="w-full  flex self-stretch order-2">
      <div
        class="p-3 inline-flex items-start cursor-pointer hover:transition-all text-slate-500 dark:text-slate-400 bg-pageBg dark:bg-dark-pageBg rounded-md w-full relative justify-center">
        <div class="inline-flex flex-col items-center justify-center w-full">
          <button
            class="p-4 text-white rounded-full absolute z-10 md:top-[41%] sm:top-auto -bottom-[13%] sm:right-auto md:-right-[34px] bg-sky-400 dark:bg-sky-500 inline-flex items-center border-8 border-solid border-white dark:border-dark-1 justify-center w-9 h-9">
            <i class="mdi mdi-arrow-right-bold hidden md:inline-flex"></i>
            <i class="mdi mdi-arrow-down-bold  md:hidden inline-flex"></i>
          </button>
          <img src="assets/icons/svg/research_planning.svg"
            class="max-w-[40%] min-w-[235px] overflow-hidden relative text-center block h-40" alt="">
          <span class="my-4 font-bold text-center text-[12px]/[1.2] capitalize ">Research <br />and planning</span>
        </div>
        <button
          class="shadow shadow-inner_shadow bg-white dark:bg-dark-1 top-[12px] left-[12px] rounded-full absolute inline-flex items-center justify-center w-4 h-4">
        </button>

      </div>
    </div>

    <div class="w-full flex self-stretch order-3 md:order-3 sm:order-4">
      <div
        class="p-3 inline-flex items-start cursor-pointer hover:transition-all text-slate-500 dark:text-slate-400 bg-pageBg dark:bg-dark-pageBg rounded-md w-full relative justify-center">
        <div class="inline-flex flex-col items-center justify-center w-full">
          <button
            class="p-4 text-white rounded-full absolute z-10 -bottom-[13%] md:-bottom-[13%] md:top-auto md:left-auto sm:top-[41%] sm:-left-[34px] bg-sky-400 dark:bg-sky-500 inline-flex items-center border-8 border-white border-solid dark:border-dark-1 justify-center w-9 h-9">
            <i class="mdi mdi-arrow-down-bold hidden md:inline-flex"></i>
            <i class="mdi mdi-arrow-left-bold hidden md:hidden sm:inline-flex"></i>
            <i class="mdi mdi-arrow-down-bold sm:hidden inline-flex"></i>

          </button>
          <img src="assets/icons/svg/solution_imp.svg"
            class="max-w-[40%] min-w-[235px] overflow-hidden relative text-center block h-40" alt="">
          <span class="my-4 font-bold text-center text-[12px]/[1.2] capitalize ">Build / Solution <br />Implementation
          </span>
        </div>
        <button
          class="shadow shadow-inner_shadow bg-white dark:bg-dark-1 top-[12px] left-[12px] rounded-full absolute inline-flex items-center justify-center w-4 h-4">
        </button>

      </div>
    </div>

    <div class="w-full flex self-stretch order-4 md:order-6 sm:order-3">
      <div
        class="p-3 inline-flex items-start cursor-pointer hover:transition-all text-slate-500 dark:text-slate-400 bg-pageBg dark:bg-dark-pageBg rounded-md w-full relative justify-center">
        <div class="inline-flex flex-col items-center justify-center w-full">
          <button
            class="p-4 text-white rounded-full absolute z-10 md:top-[41%] md:-left-[34px] sm:top-auto sm:left-auto -bottom-[13%] bg-sky-400 dark:bg-sky-500 inline-flex items-center border-8 border-solid border-white dark:border-dark-1 justify-center w-9 h-9">
            <i class="mdi mdi-arrow-left-bold hidden md:inline-flex"></i>
            <i class="mdi mdi-arrow-down-bold  md:hidden inline-flex"></i>
          </button>
          <img src="assets/icons/svg/review.svg"
            class="max-w-[40%] min-w-[235px] overflow-hidden relative text-center block h-40" alt="">
          <span class="my-4 font-bold text-center text-[12px]/[1.2] capitalize ">Review and <br />improvement</span>
        </div>
        <button
          class="shadow shadow-inner_shadow bg-white dark:bg-dark-1 top-[12px] left-[12px] rounded-full absolute inline-flex items-center justify-center w-4 h-4">
        </button>
      </div>
    </div>

    <div class="w-full flex self-stretch order-5 md:order-5 sm:order-5">
      <div
        class="p-3 inline-flex items-start cursor-pointer hover:transition-all text-slate-500 dark:text-slate-400 bg-pageBg dark:bg-dark-pageBg rounded-md w-full relative justify-center">
        <div class="inline-flex flex-col items-center justify-center w-full">
          <button
            class="p-4 text-white rounded-full absolute z-10 -bottom-[13%] sm:top-[41%] md:-left-[34px] sm:-right-[34px] bg-sky-400 dark:bg-sky-500 inline-flex items-center border-8 border-solid border-white dark:border-dark-1 justify-center w-9 h-9">
            <i class="mdi mdi-arrow-left-bold hidden md:inline-flex"></i>
            <i class="mdi mdi-arrow-right-bold hidden  md:hidden sm:inline-flex"></i>
            <i class="mdi mdi-arrow-down-bold sm:hidden inline-flex"></i>

          </button>
          <img src="assets/icons/svg/adoption.svg"
            class="max-w-[40%] min-w-[235px] overflow-hidden relative text-center block h-40" alt="">
          <span class="my-4 font-bold text-center text-[12px]/[1.2] capitalize ">Release<br /> and Adoption</span>
        </div>
        <button
          class="shadow shadow-inner_shadow bg-white dark:bg-dark-1 top-[12px] left-[12px] rounded-full absolute inline-flex items-center justify-center w-4 h-4">
        </button>
      </div>
    </div>

    <div class="w-full flex self-stretch order-6 md:order-4 sm:order-6">
      <div
        class="p-3 inline-flex items-start cursor-pointer hover:transition-all text-slate-500 dark:text-slate-400 bg-pageBg dark:bg-dark-pageBg rounded-md w-full relative justify-center">
        <div class="inline-flex flex-col items-center justify-center w-full">
          <img src="assets/icons/svg/monitoring_support.svg"
            class="max-w-[40%] min-w-[235px] overflow-hidden relative text-center block h-40" alt="">
          <span class="my-4 font-bold text-center text-[12px]/[1.2] capitalize ">Monitoring <br />and Support</span>
        </div>
        <button
          class="shadow shadow-inner_shadow bg-white dark:bg-dark-1 top-[12px] left-[12px] rounded-full absolute inline-flex items-center justify-center w-4 h-4">
        </button>
      </div>
    </div>

  </div>

  <div class="mt-10 flex flex-wrap">
    <div class="my-1 px-1 sm:w-full md:w-1/2 flex self-stretch hover:translate-y-sm hover:duration-700">
      <div
        class="flex p-4 mb-4 border border-slate-900/10 dark:border-slate-50/[0.06] rounded-lg bg-transparent dark:text-blue-400 w-full">
        <div
          class="flex-shrink-0 inline-flex items-center justify-center w-8 h-8 rounded mr-3 flex items-start bg-purple-500">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="w-[17px] text-white">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
            <path
              d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
              fill="currentColor"></path>
          </svg>
        </div>
        <div class="mt-1.5">
          <span class="text-xs/[16px] text-slate-700 dark:text-slate-200 mt-1">We follow japanese methods for Improved focus and productivity.</span>
          <ul class="mt-1.5 ml-4 text-slate-500 dark:text-slate-400 text-xmd list-disc list-inside">
            <li>Ikigai & Kaizen & Kakeibo.</li>
            <li>Pomodoro Technique.</li>
            <li>Forest Bathing (Shinrin-Yoku).</li>
            <li>Shitsuke, Seiri, Seiton, Seiso, Seiketsu (5S).</li>
            <li>Shoshin.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="my-1 px-1 sm:w-full md:w-1/2 flex self-stretch hover:translate-y-sm hover:duration-700">
      <div class="p-4 mb-4 border border-slate-900/10 dark:border-slate-50/[0.06] rounded-lg bg-transparent w-full">
        <div class="flex items-start">
          <div
            class="flex-shrink-0 inline-flex items-center justify-center w-8 h-8 rounded mr-3 flex items-start bg-teal-500">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="w-[17px] text-white">
              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
              <path
                d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                fill="currentColor"></path>
            </svg>
          </div>
          <div class="flex justify-start flex-col mt-2">
            <h3 class="text-xs/[16px] text-slate-700 dark:text-slate-200">Are you ready to work with us?</h3>
            <div class="mt-2 mb-4 text-slate-500 dark:text-slate-400 text-xmd">
              Take the first step and apply now to explore the possibilities of becoming a valued member of our team.
            </div>
            <div class="flex">
              <button type="button" (click)="isSendingMail()"
                class="text-sky-500 bg-transparent border hover:text-white focus:outline-none rounded text-xs px-3 py-2.5 text-center hover:bg-sky-500 border-sky-500 dark:text-sky-400 dark:hover:text-white focus:none">
                Request a quote
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-model [showPopup]="showPopup" [sendMail]="sendingMail" (close)="closePopup($event)"></app-model>