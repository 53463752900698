<footer class="bg-white mt-14 dark:bg-dark-1 border-t border-slate-900/10 dark:border-slate-50/[0.06]">
  <div class="w-full max-w-screen-xl mx-auto p-4 md:py-6 px-7">
      <div class="sm:flex sm:items-center sm:justify-between">
        <button class="text-sky-500 mr-3 flex-none overflow-hidden md:w-auto text-lg font-bold"
        (click)="menuService.scrollView('initial')">
          <div class="inline-flex items-center relative">
            <picture>
              <img src="assets/images/Logo.png" alt="Logo" class="w-12">
            </picture>
              <p class="text-slate-700 dark:text-slate-200 top-6 left-9 text-xs/[2.4] font-black">Tym</p>
              <span class="capitalize text-sky-500 relative text-[6px] font-black top-[10px] right-[26px] tracking-wide">For you by you</span>
          </div>
        </button>
          <ul class="flex flex-wrap justify-center items-center mb-6 text-sm font-medium sm:mb-0 text-slate-700 dark:text-slate-200">
              <li>
                  <a href="javascript:;" (click)="menuService.scrollView('projects')" class="mr-4 hover:underline md:mr-6 text-xs hover:text-sky-500 dark:hover:text-sky-400">Projects</a>
              </li>
              <li>
                  <a href="javascript:;" (click)="menuService.scrollView('service')" class="mr-4 hover:underline md:mr-6 text-xs hover:text-sky-500 dark:hover:text-sky-400">Services</a>
              </li>
              <li>
                  <a href="javascript:;" (click)="menuService.scrollView('about')" class="mr-4 hover:underline md:mr-6 text-xs hover:text-sky-500 dark:hover:text-sky-400">About</a>
              </li>
              <li>
                  <a href="javascript:;" (click)="menuService.scrollView('contact')" class="hover:underline text-xs hover:text-sky-500 dark:hover:text-sky-400">Contact</a>
              </li>
          </ul>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:mt-4 lg:mb-8" />
      <span class="block text-sm text-slate-500 dark:text-slate-400 text-center dark:text-gray-400">© {{ year }} <a href="javascript:;" class="hover:underline">i2tym™</a>. All Rights Reserved.</span>
  </div>
</footer>




