<div>
    <h6 class="text-lg leading-tight font-bold  text-slate-700 dark:text-slate-200">
        Want to get answers 
        <span class="text-sky-500">
          faster?
        </span>
      </h6>
      <span class="text-xs/[16px] text-slate-500 dark:text-slate-400 text-xmd">Fill out our short form brief and we are contact you soon</span>
      <form class="mt-5" novalidate="novalidate" [formGroup]="userDataForm">
        <div class="mb-6">
          <input
            type="text"
            placeholder="Your Name"
            formControlName="userName"
            class="text-sky-500 bg-white dark:bg-dark-1 border-slate-900/10 dark:border-dark-border focus:border-primary w-full rounded border py-3 px-[14px] text-xs outline-none focus-visible:shadow-none"
          />
          <div *ngIf="userDataFormControls.userName.errors" class="absolute w-100 flex items-center justify-start">
            <span class="text-xsm pt-0.5 pl-0.5 text-red-500" *ngIf="submitted && userDataFormControls.userName.errors.required">Your name is required.</span>
            <span class="text-xsm pt-0.5 pl-0.5 text-red-500" *ngIf="userDataFormControls.userName.errors.minlength">Your name should be 3
              character.</span>
          </div>
        </div>
        <div class="mb-6">
          <input
            type="email"
            placeholder="Your Email"
            formControlName="email"
            class="form-control form-control-lg form-control-solid" 
            class="text-sky-500 bg-white dark:bg-dark-1 border-slate-900/10 dark:border-dark-border focus:border-primary w-full rounded border py-3 px-[14px] text-xs outline-none focus-visible:shadow-none"
          />
          <div *ngIf="submitted && userDataFormControls.email.errors" class="absolute w-100 flex items-center justify-start">
            <span class="text-xsm pt-0.5 pl-0.5 text-red-500" *ngIf="userDataFormControls.email.errors.required">Mail address is required.</span>
            <span class="text-xsm pt-0.5 pl-0.5 text-red-500" *ngIf="userDataFormControls.email.errors.email">Email must be a valid email address.</span>
          </div>
        </div>
        <div class="mb-6">
          <input
            type="text"
            placeholder="Your Phone"
            formControlName="phone"
            maxlength="10"
            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
            class="form-control form-control-lg form-control-solid" 
            class="text-sky-500 bg-white dark:bg-dark-1 border-slate-900/10 dark:border-dark-border focus:border-primary w-full rounded border py-3 px-[14px] text-xs outline-none focus-visible:shadow-none"
          />
          <div *ngIf="userDataFormControls.phone.errors" class="absolute w-100 flex items-center justify-start">
            <span class="text-xsm pt-0.5 pl-0.5 text-red-500" *ngIf="submitted && userDataFormControls.phone.errors.required">Contact number is required.</span>
            <span class="text-xsm pt-0.5 pl-0.5 text-red-500" *ngIf="userDataFormControls.phone.errors.minlength">Contact number should be 10 digits.</span>
          </div>
        </div>
        <div class="mb-6 relative">
          <textarea
            rows="6"
            placeholder="Your Message"
            formControlName="message"
            class="text-sky-500 bg-white dark:bg-dark-1 border-slate-900/10 dark:border-dark-border focus:border-primary w-full resize-none rounded border py-3 px-[14px] text-xs outline-none focus-visible:shadow-none"
          ></textarea>
          <div *ngIf="submitted && userDataFormControls.message.errors" class="absolute -bottom-2.5 w-100 flex items-center justify-start">
            <span class="text-xsm pl-0.5 text-red-500" *ngIf="userDataFormControls.message.errors.required">Your message is required.</span>
          </div>
        </div>
        <div>
          <button
            type="submit"
            class="bg-sky-500 border text-white border-primary w-full rounded border p-3 hover:bg-sky-600 border-sky-500 dark:hover:text-white transition hover:bg-opacity-90"
            (click)="sendUserData()"
            >
            <span *ngIf="!isReady"> Send Message </span>
            <span class="inline-flex" *ngIf="isReady"> Sending Message... 
              <span class="ml-4">
                <svg class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              </span>
          </span>
    
          </button>
        </div>
      </form>
  </div>

  <success-party-model *ngIf="menuService.contactDetailsSubmitted" />
  <error-component *ngIf="isError" (closeError)="closeErrorComp($event)" />