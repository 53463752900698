<div
  [ngClass]="
    (showMobileMenu$ | async)
      ? 'pointer-events-auto scale-100 animate-fade-in-up opacity-100 duration-200'
      : 'pointer-events-none scale-95 opacity-0 duration-100 ease-out'
  "
  class="fixed inset-x-0 top-0 z-40 origin-top-right transform p-2 transition md:hidden">
  <div
    class="divide-y-2 divide-gray-500 text-slate-700 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-dark-1">
    <div class="pt-5 pb-6">
      <div class="flex items-center justify-between px-5 border-b pb-3 mx-3.5 border-slate-900/10 dark:border-slate-50/[0.06]">
        <div>
          <div class="flex items-center justify-start sm:order-2 md:mr-10 lg:hidden">
            <button class="text-sky-500 mr-3 flex-none overflow-hidden md:w-auto text-lg font-bold"
              (click)="menuService.scrollView('initial')">
                <div class="inline-flex items-center relative">
                  <picture>
                    <img src="assets/images/Logo.png" alt="Logo" class="w-12">
                  </picture>
                    <p class="text-slate-700 dark:text-slate-200 top-6 left-9 text-xs/[2.4] font-black">Tym</p>
                    <span class="capitalize text-sky-500 relative text-[6px] font-black top-[10px] right-[26px] tracking-wide">For you by you</span>
                </div>
              </button>
          </div>
        </div>
        <div class="flex items-center">

          <button (click)="toggleTheme()">
            <svg-icon
            [src]="themeService.isDark ? 'assets/icons/moon.svg' : 'assets/icons/sun.svg'"
            >
          </svg-icon>
          </button>
          <div class="border-l h-6 border-slate-200 mx-3 dark:border-dark-border"> </div>
          <button
          (click)="toggleMobileMenu()"
          type="button"
          class="inline-flex text-sm items-center justify-center rounded-full p-2 text-gray-400 bg-pageBg dark:bg-dark-pageBg hover:text-sky-500">
          <span class="sr-only">Close menu</span>
          <svg-icon src="assets/icons/close.svg"> </svg-icon>
        </button>


        </div>
      </div>
      <div
        class="scrollbar-thumb-rounded scrollbar-track-rounded max-h-[500px] overflow-y-auto px-5 scrollbar-thin scrollbar-track-gray-50 scrollbar-thumb-gray-100 dark:scrollbar-thumb-night-500 dark:scrollbar-track-night-600">
        <div class="pt-4 flex items-center justify-center">
          <ul class="flex flex-col text-slate-700 dark:text-slate-200 text-center">
              <li class="mb-2.5"><a class="text-xs p-1 hover:text-sky-500 dark:hover:text-sky-400" href="javascript:;" (click)="menuService.scrollView('projects')">Projects</a></li>
              <li class="mb-2.5"><a class="text-xs p-1 hover:text-sky-500 dark:hover:text-sky-400" href="javascript:;" (click)="menuService.scrollView('service')">Services</a></li>
              <li class="mb-2.5"><a class="text-xs p-1 hover:text-sky-500 dark:hover:text-sky-400" href="javascript:;" (click)="menuService.scrollView('about')">About Us</a></li>
              <li class="border p-1 px-5 border-sky-400 rounded-full"><a href="javascript:;" (click)="menuService.scrollView('contact')"
                  class="text-xs hover:text-sky-500 dark:hover:text-sky-400">Contact US</a>
              </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>