<div class="bg-black z-50 fixed top-0 left-0 w-screen h-screen opacity-50" *ngIf="showPopup"></div>
<div class="flex items-center justify-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full" *ngIf="showPopup">
    <div class="relative w-full max-w-[65rem] max-h-full">
        <div class="relative bg-white rounded-lg shadow-modelShadow dark:bg-dark-1">
            <button type="button" (click)="closePopup()" class="z-50 absolute top-3 right-2.5 text-gray-400 bg-pageBg hover:text-sky-500 rounded-full text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:bg-dark-pageBg">
                <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="w-full rounded-lg  p-4  sm:p-12 border shadow-lg border-gray-200 dark:border-slate-50/[0.06]">
                <div class="flex flex-col items-start  pl-5">
                    <a class="text-sm/[16px] font-bold text-slate-700 dark:text-slate-200 inline-flex items-baseline"
                      href="/"><span
                        class="bg-{{projectDetals.color}}-600 uppercase text-white dark:text-slate-200 inline-flex w-8 h-8 items-center justify-center rounded text-xl">{{projectDetals.title}}</span>{{projectDetals.name}}
                    </a>
                    <span class="ml-10 text-{{projectDetals.color}}-600 -mt-2 text-xsm font-semibold">{{projectDetals.type}}</span>
                  </div>
                  <div class="modal-body grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-4 max-h-[30rem] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-300 p-5">
                    <div class="border border-slate-900/10 dark:border-slate-50/[0.06] rounded-lg p-4 shadow shadow-md" *ngFor="let details of projectDetals.details">
                        <h6 class="flex items-baseline text-slate-700 dark:text-slate-200 -mt-px text-sm-11 mb-1">
                            <pre class="w-2 h-2 rounded-full bg-sky-500 mr-1"></pre>
                            {{details.title}}
                          </h6>
                          <p class="text-xsm text-slate-500 dark:text-slate-400 ml-3">{{details.desc}}</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
