import { Component } from '@angular/core';
import { MenuService } from '../../services/menu-service.service';
import { Observable } from 'rxjs';
import { ThemeService } from 'src/app/services/theme-services.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent {
  public showMobileMenu$: Observable<boolean> = new Observable<boolean>();

  constructor(public menuService:MenuService,public themeService: ThemeService) {
    this.showMobileMenu$ = this.menuService.showMobileMenu$;
   }

  public toggleMobileMenu() {
    this.menuService.showMobileMenu = false;
  }

  toggleTheme() {
    this.themeService.theme = !this.themeService.isDark ? 'dark' : 'light';
  }
  
}
