import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as confetti from 'canvas-confetti';
import { MenuService } from 'src/app/services/menu-service.service';

@Component({
  selector: 'success-party-model',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './success-party-model.component.html',
  styleUrls: ['./success-party-model.component.scss']
})
export class SuccessPartyModelComponent {
  public canvas:any = document.getElementById('custom-canvas');
  constructor( public menuService:MenuService ) {

      const confettiHandler = confetti.create(this.canvas, {
            resize: true
        });
        confettiHandler({
          angle: 90,
          particleCount: 250,
          spread: 100,
        });
    }
    closePopup(closeValue:boolean) {
      this.menuService.contactDetailsSubmitted = closeValue;
    }
}
