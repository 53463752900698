import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MenuService } from 'src/app/services/menu-service.service';
import { SuccessPartyModelComponent } from '../success-party-model/success-party-model.component';
import { ApiService } from 'src/app/services/apiService';
import { delay, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ErrorComponent } from '../error-component/error-component.component';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'send-details-block',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SuccessPartyModelComponent,
    ErrorComponent,
    HttpClientModule
  ],
  providers: [ApiService],
  templateUrl: './send-details-block.component.html',
  styleUrls: ['./send-details-block.component.scss'],
})
export class SendDetailsBlockComponent implements OnInit, OnDestroy {
    public userDataForm!: FormGroup; // user data form
    public submitted: boolean = false;
    public isReady: boolean = false;
    @Output() close = new EventEmitter<boolean>();
    private destroy$: Subject<void> = new Subject<void>();
    public isError = false;
    constructor(
        private formBuilder: FormBuilder,
        public menuService: MenuService,
        private apiSevice: ApiService
    ) {}

    ngOnInit(): void {
        this.loadForm();
    }

    loadForm() {
        this.userDataForm = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        userName: [null, [Validators.required, Validators.minLength(3)]],
        phone: [null, [Validators.required, Validators.minLength(10)]],
        message: [null, Validators.required],
        });
    }

    get userDataFormControls() {
        return this.userDataForm.controls;
    }

    sendUserData() {
        this.submitted = true;
        if (this.userDataForm.invalid) { return; }
        let updateData = {
        email: this.userDataForm.value.email,
        name: this.userDataForm.value.userName,
        phone: this.userDataForm.value.phone,
        query: this.userDataForm.value.message,
        };
        this.isReady = true;

        this.apiSevice
        .post(updateData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {                
            if (res.response.status == 'success') {
                this.isReady = false;
                this.userDataForm.reset();
                this.submitted = false;
                this.close.emit(false);
                this.menuService.contactDetailsSubmitted = true;
            }
            },
            (error) => {
                this.isReady = false;
                this.submitted = false;
                this.isError = true;
            }
        );
    }

    closeErrorComp(data: boolean) {
        this.isError = data;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
