import { Component } from '@angular/core';
import { ThemeService } from './services/theme-services.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Portfolio';

  constructor(public themeService: ThemeService) {
    window.addEventListener("blur", () => {
      document.title = "Come Back 😎";
    })
    window.addEventListener("focus", () => {
      document.title = this.title;
    })
   } 
}
