import { Component } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent {
  show: boolean = false;
  loading: boolean = false;
  showPopup = false;
  public start = 0; // Array slicing start value
  public end = 3; // Array slicing end value
  public projectsDetails:Array<any> = [];
  public passingProjectItems:any = {};
  projectDetailsLoading = false;
  public itemIndex:any = ""; 
  constructor() {
    this.projectsDetails = [
      {
        color: 'fuchsia',
        nameTitle: 'E',
        name: '-commerce',
        url: 'i2Tym-ecommerce.com',
        type: 'Web application',
        smallDesc: 'A modern multi vendor platform to buy and sell products.',
        description: `A software platform or website application that enables online purchasing and selling of goods and services is known as
                      an eCommerce application. Customers can browse, choose, and buy items through a virtual storefront, which enables
                      businesses to exhibit their goods or services to a large audience. E-commerce software has grown in popularity as more
                      consumers favour the ease of online shopping.`,
        detailedDesc: [
          {
            title: 'Product Catalog',
            desc: `The application provides a comprehensive catalogue of products or services offered by the business. Each item
                     typically includes details such as images, descriptions, prices, and availability.`,
          },
          {
            title: 'User Accounts',
            desc: `Customers can create accounts on the platform, which allows
              them to save their preferences, view order history, and receive personalized recommendations.`,
          },
          {
            title: 'Shopping Cart',
            desc: `Users can add items to their virtual shopping cart as they browse the catalogue. The cart holds the selected items until the user is ready to proceed to checkout.`,
          },
          {
            title: 'Checkout and Payment Processing',
            desc: `The application offers a secure and streamlined checkout process. Customers enter their payment information, and the platform processes the transaction using various payment gateways.`,
          },
          {
            title: 'Order Management',
            desc: `The application manages the entire order fulfilment process, from order confirmation to shipment tracking and delivery notifications.`,
          },
          {
            title: 'Search and Filters',
            desc: `Users can search for specific products or use filters to narrow down their search results based on criteria such as price, category, brand, etc.`,
          },
          {
            title: 'Reviews and Ratings',
            desc: `Customers can leave reviews and ratings for products they have purchased, helping other shoppers make informed decisions.`,
          },
          {
            title: 'Security and Privacy',
            desc: `E-commerce applications must have robust security measures in place to protect sensitive customer information and ensure secure transactions.`,
          },
          {
            title: 'Responsive Design',
            desc: `The application is designed to be responsive, adapting to different devices (e.g., desktops, tablets, smartphones) to provide a seamless shopping experience.`,
          },
          {
            title: 'Inventory Management',
            desc: `The platform tracks and manages the availability of products, ensuring that customers are not able to purchase out-of-stock items.`,
          },
          {
            title: 'Marketing and Promotions',
            desc: `E-commerce applications often include marketing features such as discount codes, promotions, and personalized recommendations to encourage customer engagement and loyalty.`,
          },
          {
            title: 'Analytics and Reporting',
            desc: `The application collects data on user behaviour, sales, and other metrics, providing insights to improve marketing strategies and optimize the user experience.`,
          },
        ],
        smallImgSet: [
          {
            title: 'Dashboard - Light',
            img: 'ecom_1.png',
          },
          {
            title: 'Product page - Light',
            img: 'ecom_2.png',
          },
          {
            title: 'Login page - Light',
            img: 'ecom_5.png',
          },
          {
            title: 'Single product - Light',
            img: 'ecom_6.png',
          },
        ],
        largeImgSet: [
          {
            title: 'Dashboard full view - Light',
            img: 'ecom_3.png',
          },
          {
            title: 'Product full view - Light',
            img: 'ecom_4.png',
          },
        ],
      },
      {
        color: 'teal',
        nameTitle: 'E',
        name: '-Learning',
        url: 'i2Tym-elearning.com',
        type: 'Web application',
        smallDesc: 'A creative live Learning Management system and collaboration platform.',
        description: `This is an eLearning application, also known as an online learning platform or Learning Management System 
                      (LMS), which is a software platform that facilitates the delivery, management, and tracking of educational content and 
                      courses over the internet. It enables individuals, students, employees, or learners of all ages to access educational 
                      resources and participate in various online learning activities. Elearning applications have become increasingly popular 
                      due to their flexibility, accessibility, and scalability.
                      `,
        detailedDesc: [
          {
            title: 'Course Creation and Management',
            desc: `The application allows instructors or content creators to design and organize courses, including lessons, modules, assessments, and multimedia content.`,
          },
          {
            title: 'User Registration and Authentication',
            desc: `Learners can create accounts on the platform, providing them with access to the courses they have enrolled in.`,
          },
          {
            title: 'Course Enrollment',
            desc: `Learners can browse through the available courses and enrol in the ones they are interested in, granting them access to the course materials.`,
          },
          {
            title: 'Multimedia Content Support',
            desc: `Elearning applications support various content formats, such as video lectures, slide presentations, interactive quizzes, audio files, and downloadable resources.`,
          },
          {
            title: 'Assessment and Quizzes',
            desc: `The platform often includes tools for creating and managing quizzes and assessments, allowing instructors to evaluate learners' progress and knowledge.`,
          },
          {
            title: 'Progress Tracking and Reporting',
            desc: `Elearning applications track learners' progress throughout the course, providing insights into completed activities, grades, and overall performance.`,
          },
          {
            title: 'Discussion Forums and Communication',
            desc: `Many eLearning platforms offer discussion forums, chat features, or messaging systems to facilitate learner-to-learner and learner-to-instructor interactions.`,
          },
          {
            title: 'Mobile Compatibility',
            desc: `Elearning applications are often designed to be accessible on various devices, including desktop computers, laptops, tablets, and smartphones.`,
          },
          {
            title: 'Gamification and Badges',
            desc: `Some platforms incorporate gamification elements, such as badges, points, or leaderboards, to motivate learners and enhance engagement.`,
          },
          {
            title: 'Analytics and Insights',
            desc: `Elearning applications collect data on learner behaviour, course performance, and engagement metrics, providing instructors and administrators with valuable insights for continuous improvement.`,
          },
          {
            title: 'Integration with Learning Tools and Services',
            desc: `Some eLearning applications can integrate with external tools and services, such as virtual classrooms, video conferencing platforms, and external content repositories.`,
          },
          {
            title: 'Security and Privacy',
            desc: `Elearning platforms prioritize security measures to protect sensitive learner data and ensure a safe learning environment.`,
          },
        ],
        smallImgSet: [
          {
            title: 'Tech stack page - Dark',
            img: '1.png',
          },
          {
            title: 'Tech stack page - Light',
            img: '2.png',
          },
          {
            title: 'Tech stack page - Light',
            img: '2.png',
          },
          {
            title: 'Tech stack page - Dark',
            img: '1.png',
          }
        ],
        largeImgSet: [
          {
            title: 'My dashboard full view - Dark',
            img: '8.png',
          },
          {
            title: 'My dashboard full view - Light',
            img: '9.png',
          },
        ],
      },
      {
        color: 'lime',
        nameTitle: 'E',
        name: '-Billing',
        subName:"inventory and billing management",
        url: 'i2Tym-billing.com',
        type: 'Web application',
        smallDesc: 'A single point application to manage billing and inventory.',
        description: `Inventory and billing software is a type of business software that combines inventory management and billing 
                      functionalities into a single integrated system. It is designed to help businesses efficiently manage their inventory levels,
                      track product stock, and streamline the billing and invoicing processes. This software is commonly used by retail stores, 
                      wholesalers, manufacturers, 
                      and other businesses that deal with physical goods and need to keep track of their inventory and sales.
                      `,
        detailedDesc: [
          {
            title: 'Inventory Management',
            desc: `The software allows businesses to maintain a detailed database of their products, including item descriptions, stock quantities, pricing, and supplier information. It tracks the movement of goods from purchase to sale, keeping the inventory up to date in real-time.`,
          },
          {
            title: 'Stock Control',
            desc: `The software helps optimize stock levels by providing alerts when inventory levels are low, enabling businesses to avoid stockouts and maintain adequate inventory levels to meet customer demand.`,
          },
          {
            title: 'Purchase Order Management',
            desc: `The software facilitates the creation and management of purchase orders to replenish stock from suppliers. It helps track the status of orders and manage the procurement process.`,
          },
          {
            title: 'Sales and Invoicing',
            desc: `The software enables businesses to create and manage sales transactions, generate invoices, and process customer payments. It can handle different payment methods and may also include automated invoicing features.`,
          },
          {
            title: 'Barcode Scanning',
            desc: `Many inventory and billing software systems support barcode scanning, making it easier to update inventory levels and process sales transactions accurately and efficiently.`,
          },
          {
            title: 'Reporting and Analytics',
            desc: `The software provides detailed reports and analytics on inventory levels, sales performance, profitability, and other key metrics. This data helps businesses make informed decisions and identify trends and opportunities.`,
          },
          {
            title: 'Integration with Point of Sale (POS) Systems',
            desc: `For businesses with physical retail locations, inventory and billing software often integrates with POS systems to streamline the sales process and update inventory in real time.`,
          },
          {
            title: 'Multi-Location Support',
            desc: `Some inventory and billing software can handle multiple store locations, warehouses, or distribution centers, allowing businesses to manage inventory across different locations.`,
          },
          {
            title: 'Customer Management',
            desc: `The software may include features to manage customer information, track purchase history, and offer loyalty programs to enhance customer relationships.`,
          },
          {
            title: 'Tax Calculation',
            desc: `The software automates tax calculations based on the applicable tax rates for each transaction, ensuring accurate billing and compliance with tax regulations.`,
          },
          {
            title: 'Security and Data Protection',
            desc: `Inventory and billing software typically have security measures in place to protect sensitive business and customer data.`,
          }
        ],
        smallImgSet: [
          {
            title: 'Tech stack page - Dark',
            img: '1.png',
          },
          {
            title: 'Tech stack page - Light',
            img: '2.png',
          },
          {
            title: 'Tech stack page - Light',
            img: '2.png',
          },
          {
            title: 'Tech stack page - Dark',
            img: '1.png',
          }
        ],
        largeImgSet: [
          {
            title: 'My dashboard full view - Dark',
            img: '8.png',
          },
          {
            title: 'My dashboard full view - Light',
            img: '9.png',
          },
        ],
      },
      {
        color: 'yellow',
        nameTitle: 'F',
        name: '-leet management',
        subName:"",
        url: 'i2Tym-fleet-management.com',
        type: 'Mobile application',
        smallDesc: 'This mobile app used for the vehicle owners.',
        description: `A fleet management application is a software platform designed to help businesses and 
                    organizations efficiently manage and monitor their fleet of vehicles. It provides a centralized system 
                    for tracking vehicle location, monitoring vehicle health and performance, optimizing routes, managing maintenance schedules, 
                    and improving overall fleet operations. Fleet management applications are commonly used by companies with large fleets of vehicles, 
                    such as transportation and logistics companies, delivery services, and public transportation agencies.
                      `,
        detailedDesc: [
          {
            title: 'GPS Tracking',
            desc: `The application uses GPS technology to track the real-time location of each vehicle in the fleet. This feature allows fleet managers to monitor vehicle movements and ensure they are on the right routes.`,
          },
          {
            title: 'Vehicle Health Monitoring',
            desc: `Fleet management applications can collect and analyze data from the vehicles' onboard diagnostic systems (OBD-II). 
                    This data helps monitor the health and performance of each vehicle, including fuel consumption, engine diagnostics, and maintenance needs.`,
          },
          {
            title: 'Route Optimization',
            desc: `The application can analyze various factors, such as traffic conditions, distance, and delivery schedules, to optimize routes for better fuel efficiency and timely deliveries.`,
          },
          {
            title: 'Driver Management',
            desc: `Fleet management applications may include features to track driver behavior, such as speeding, harsh braking, and idling, to promote safer driving practices and reduce fuel consumption.`,
          },
          {
            title: 'Fuel Management',
            desc: `The application can monitor fuel usage and identify potential fuel theft or inefficiencies. This helps businesses reduce fuel costs and improve fuel economy.`,
          },
          {
            title: 'Maintenance Scheduling',
            desc: `Fleet management applications can schedule and track vehicle maintenance tasks, including regular servicing, inspections, and repairs, ensuring vehicles are well-maintained and in good working condition.`,
          },
          {
            title: 'Alerts and Notifications',
            desc: `The application can send real-time alerts and notifications to fleet managers and drivers for critical events, such as vehicle breakdowns, maintenance reminders, and unauthorized vehicle usage.`,
          },
          {
            title: 'Geofencing',
            desc: `Fleet management applications may support geofencing, allowing fleet managers to define virtual boundaries for vehicles. When a vehicle enters or exits these boundaries, the system triggers alerts.`,
          },
          {
            title: 'Compliance and Reporting',
            desc: `The application can help with regulatory compliance by generating reports on driver hours, vehicle inspections, and other compliance-related data.`,
          },
          {
            title: 'Integration with Telematics and IoT Devices',
            desc: `Fleet management applications often integrate with telematics devices and Internet of Things (IoT) sensors installed in vehicles to gather and analyze data in real-time.`,
          },
          {
            title: 'Asset Tracking:',
            desc: `Some fleet management applications support the tracking of other assets beyond vehicles, such as trailers and containers.`,
          },
          {
            title: 'Data Analytics',
            desc: `Fleet management applications collect vast amounts of data, and data analytics tools can provide insights and patterns that help optimize fleet performance and make data-driven decisions.`,
          }
        ],
        smallImgSet: [
          {
            title: 'Tech stack page - Dark',
            img: '1.png',
          },
          {
            title: 'Tech stack page - Light',
            img: '2.png',
          },
          {
            title: 'Tech stack page - Light',
            img: '2.png',
          },
          {
            title: 'Tech stack page - Dark',
            img: '1.png',
          }
        ],
        largeImgSet: [
          {
            title: 'My dashboard full view - Dark',
            img: '8.png',
          },
          {
            title: 'My dashboard full view - Light',
            img: '9.png',
          },
        ],
      }
    ];
  }
  public showLoader() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.end = this.projectsDetails.length;
      this.show = true;
    }, 2000);
  }
  showProjectDetails(proDetails:Array<any>,color:string,title:string,name:string,type:string) {
      this.projectDetailsLoading = true;
      this.itemIndex = this.projectsDetails.map((res:any) => res.name).indexOf(name);
      setTimeout(() => {
        this.passingProjectItems = {
          details:proDetails,
          color:color,
          title:title,
          name:name,
          type:type
        }
        this.showPopup= true;
        this.itemIndex= ""
    }, 2000);
  }
  public closePopup(data:any) {
    this.projectDetailsLoading = data;
    this.showPopup = data;
   }
}
