<div
  class="sticky top-0 z-40  w-full backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 dark:border-slate-50/[0.06] bg-white/70 supports-backdrop-blur:bg-white/60 dark:bg-dark-backDropBlur">
  <div class="max-w-8xl mx-auto transition-all duration-300">
    <div class="py-4 border-b border-slate-900/10 lg:px-8 lg:border-0 dark:border-slate-300/10 mx-4 lg:mx-0">
      <div class="relative flex items-center">
        <button class="text-sky-500 mr-3 flex-none overflow-hidden md:w-auto text-lg font-bold"
        (click)="menuService.scrollView('initial')">
          <div class="inline-flex items-center relative">
            <picture>
              <img src="assets/images/Logo.png" alt="Logo" class="w-12">
            </picture>
              <p class="text-slate-700 dark:text-slate-200 top-6 left-9 text-xs/[2.4] font-black">Tym</p>
              <span class="capitalize text-sky-500 relative text-[6px] font-black top-[10px] right-[26px] tracking-wide">For you by you</span>
          </div>
        </button>
        <div class="relative hidden md:flex items-center ml-auto">
          <nav class="text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200">
            <ul class="flex items-center space-x-8">
              <li><a class="text-xs p-1 hover:text-sky-500 dark:hover:text-sky-400" href="javascript:;" (click)="menuService.scrollView('projects')">Projects</a></li>
              <li><a class="text-xs p-1 hover:text-sky-500 dark:hover:text-sky-400" href="javascript:;" (click)="menuService.scrollView('service')">Services</a></li>
              <li><a class="text-xs p-1 hover:text-sky-500 dark:hover:text-sky-400" href="javascript:;" (click)="menuService.scrollView('about')">About Us</a></li>
              <li class="border p-1 px-5 border-sky-400 rounded-full"><a href="javascript:;" (click)="menuService.scrollView('contact')"
                  class="text-xs hover:text-sky-500 dark:hover:text-sky-400">Contact US</a></li>
            </ul>
          </nav>
          <div class="flex items-center border-l border-slate-200 ml-6 pl-6 dark:border-dark-border">
            <label  class="sr-only">Theme</label>
              <button (click)="toggleTheme()">
                <svg-icon
                [src]="themeService.isDark ? 'assets/icons/moon.svg' : 'assets/icons/sun.svg'"
                >
              </svg-icon>
              </button>
            </div>
        </div>
        <button type="button"
          class="ml-auto text-slate-500 w-8 h-8 -my-1 flex items-center justify-center hover:text-slate-600 md:hidden dark:text-slate-400 dark:hover:text-slate-300"
          (click)="toggleMobileMenu()">
          <span
              class="sr-only">Navigation</span><svg width="24" height="24" fill="none" aria-hidden="true">
              <path
                d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        </button>
      </div>
    </div>
  </div>
</div>

<app-header-mobile></app-header-mobile>


