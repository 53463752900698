

<div  [ngClass]="{ dark: this.themeService.isDark }">
  <div class="h-full dark:bg-dark-1">
  <app-header></app-header>
    <app-description></app-description>
    <app-business></app-business>
    <app-process></app-process>
    <app-projects></app-projects>
    <app-work></app-work>
    <app-testimonials></app-testimonials>
    <app-contact></app-contact>
    <router-outlet></router-outlet>
    <app-back-to-top></app-back-to-top>
    <app-footer></app-footer>
  </div>
</div>


