<div class="mx-auto mt-8 px-6 md:px-8 lg:px-16 xl:px-30 2xl:px-55" id="contact">
    <span class="uppercase text-sky-500 font-bold">Hire us</span>
    <h1 class="text-3xl leading-tight font-bold  text-slate-700 dark:text-slate-200">
        Let's start working <br/>
        on your
      <span class="text-sky-500">
        project
      </span>
    </h1>
    <div class="mt-8 flex flex-wrap relative z-10">
      <div class="w-full md:w-2/5">
          <div class="mb-12 max-w-[570px] lg:mb-0">
            <contact-details-block />
          </div>
      </div>
      <div class="md:w-3/5 w-full inline-flex justify-end">
            <div class="relative md:w-4/5 w-full rounded-lg  p-8  sm:p-12 border shadow-lg border-gray-200 dark:border-slate-50/[0.06]">
              <send-details-block/>
          </div>
      </div>
    </div>
</div>
