import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'contact-details-block',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './contact-details-block.component.html',
  styleUrls: ['./contact-details-block.component.scss']
})
export class ContactDetailsBlockComponent {
  @Input() contact:boolean = false; 
}
