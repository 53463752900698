
      <div class="main-crousal">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="flex items-center flex-col w-full h-full justify-between">
                        <div class="basis-full">
                            <div class="flex items-center">
                                <div class="bg-sky-100  inline-block relative rounded-lg  w-12 dark:bg-dark-1 mr-3">
                                    <img src="assets/icons/elearning.png" alt="" class="w-12 h-12 inline-block p-3 filter-none">
                                </div>
                                <h6 class="text-sm/[16px] font-bold text-sky-500">E-Learning <br />Web and mobile app</h6>
                            </div>
                            <div class="flex flex-wrap mt-5">
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Live Events</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Courses</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Recorded sessions</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Tech stack</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Certificates</span>    
                            </div>
                        </div>
                        <div class="basis-full flex justify-center my-3 items-end">
                            <div class="drop-shadow-carousal-image-drop-shadow animate-elearn-animation bg-[length:100%_100%] w-64 h-36"></div>

                                <!-- <img src="assets/images/elearn_slide_1.png" alt="" class="lg:w-full md:w-6/12 animate-image-fade opacity-100">
                                <img src="assets/images/elearn_slide_2.png" alt="" class="lg:w-full top-0 left-0 animate-image-fade md:w-[48%] absolute md:translate-x-1/2 lg:translate-x-0"> -->
                        </div>
                    </div>
                </div>
                </ng-template>  
    
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="flex items-center flex-col w-full h-full justify-between">
                        <div class="basis-full">
                            <div class="flex items-center">
                                <div class="bg-sky-100  inline-block relative rounded-lg  w-12 dark:bg-dark-1 mr-3">
                                    <img src="assets/icons/shopping.png" alt="" class="w-12 h-12 inline-block p-3 filter-none">
                                </div>
                                <h6 class="text-sm/[16px] font-bold text-sky-500">E-Commerce <br />Web and mobile app</h6>
                            </div>
                            <div class="flex flex-wrap mt-5">
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Inventory</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Order/Payment</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Admin Module</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Modularized</span>
        
                            </div>
                        </div>
                        <div class="basis-full flex justify-center my-3 items-end">
                            <div class="drop-shadow-carousal-image-drop-shadow animate-ecommerce-animation bg-[length:100%_100%] w-64 h-36"></div>
                            <!-- <img src="assets/images/ecom_slide_1.png" alt="" class="lg:w-full md:w-6/12 animate-image-fade opacity-100">
                            <img src="assets/images/ecom_slide_2.png" alt="" class="lg:w-full top-0 left-0 animate-image-fade md:w-[48%] absolute md:translate-x-1/2 lg:translate-x-0"> -->
                    </div>
                    </div>
                </div>
            </ng-template>  
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="flex items-center flex-col w-full h-full justify-between">
                        <div class="basis-full">
                            <div class="flex items-center">
                                <div class="bg-sky-100  inline-block relative rounded-lg  w-12 dark:bg-dark-1 mr-3">
                                    <img src="assets/icons/job.svg" alt="" class="w-12 h-12 inline-block p-3 filter-none">
                                </div>
                                <h6 class="text-sm/[16px] font-bold text-sky-500">HRMS & Job Portal <br />Web application</h6>
                            </div>
                            <div class="flex flex-wrap mt-5">
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Job listing</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Quiz</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Admin module</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Notifications​​</span>
        
                            </div>
                        </div>
                        <div class="basis-full flex justify-center my-3 items-end">
                            <div class="drop-shadow-carousal-image-drop-shadow animate-elearn-animation bg-[length:100%_100%] w-64 h-36"></div>
                            <!-- <img src="assets/images/carousal_1.png" alt="" class="lg:w-full md:w-6/12 animate-image-fade opacity-100">
                            <img src="assets/images/allcourse.png" alt="" class="lg:w-[94%] top-0 left-0 animate-image-fade md:w-[48%] absolute md:translate-x-1/2 lg:translate-x-0"> -->
                    </div>
                    </div>
                </div>
            </ng-template>  
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="flex items-center flex-col w-full h-full justify-between">
                        <div class="basis-full">
                            <div class="flex items-center">
                                <div class="bg-sky-100  inline-block relative rounded-lg  w-12 dark:bg-dark-1 mr-3">
                                    <img src="assets/icons/fleet.svg" alt="" class="w-12 h-12 inline-block p-3 filter-none">
                                </div>
                                <h6 class="text-sm/[16px] font-bold text-sky-500">Fleet Management <br />Web and mobile app</h6>
                            </div>
                            <div class="flex flex-wrap mt-5">
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Live Tracking</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Spend/Expense </span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Intuitive UI </span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Driver Behaviour</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Fuel monitor</span>    
                            </div>
                        </div>
                        <div class="basis-full flex justify-center my-3 items-end">
                            <div class="drop-shadow-carousal-image-drop-shadow animate-ecommerce-animation bg-[length:100%_100%] w-64 h-36"></div>
                            <!-- <img src="assets/images/carousal_1.png" alt="" class="lg:w-full md:w-6/12 animate-image-fade opacity-100">
                            <img src="assets/images/allcourse.png" alt="" class="lg:w-[94%] top-0 left-0 animate-image-fade md:w-[48%] absolute md:translate-x-1/2 lg:translate-x-0"> -->
                    </div>
                    </div>
                </div>
            </ng-template> 
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="flex items-center flex-col w-full h-full justify-between">
                        <div class="basis-full">
                            <div class="flex items-center">
                                <div class="bg-sky-100  inline-block relative rounded-lg  w-12 dark:bg-dark-1 mr-3">
                                    <img src="assets/icons/qanda.svg" alt="" class="w-12 h-12 inline-block p-3 filter-none">
                                </div>
                                <h6 class="text-sm/[16px] font-bold text-sky-500">Q & A<br />Collaborative platform</h6>
                            </div>
                            <div class="flex flex-wrap mt-5">
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Interactive</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Modularized </span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Chat/Comments</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Posting</span>
                            </div>
                        </div>
                        <div class="basis-full flex justify-center my-3 relative items-end">
                            <div class="drop-shadow-carousal-image-drop-shadow animate-elearn-animation bg-[length:100%_100%] w-64 h-36"></div>
                            <!-- <img src="assets/images/carousal_1.png" alt="" class="lg:w-full md:w-6/12 animate-image-fade opacity-100">
                            <img src="assets/images/allcourse.png" alt="" class="lg:w-[94%] top-0 left-0 animate-image-fade md:w-[48%] absolute md:translate-x-1/2 lg:translate-x-0"> -->
                    </div>
                    </div>
                </div>
            </ng-template> 
            <ng-template carouselSlide>
                <div class="slide h-full">
                    <div class="flex items-center flex-col w-full h-full justify-between">
                        <div class="basis-full">
                            <div class="flex items-center">
                                <div class="bg-sky-100  inline-block relative rounded-lg  w-12 dark:bg-dark-1 mr-3">
                                    <a href="https://www.flaticon.com/free-icons/receipt" title="receipt icons"></a>
                                    <img src="assets/icons/billing.svg" alt="" class="w-12 h-12 inline-block p-3 filter-none">
                                </div>
                                <h6 class="text-sm/[16px] font-bold text-sky-500">Inventory billing system<br />Web application</h6>
                            </div>
                            <div class="flex flex-wrap mt-5">
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Inventory</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Billing/Payment </span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">IStock Management</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Admin module</span>
                                <span class="mr-3 border font-medium mb-2.5 p-0.5 px-3.5 border-slate-300 rounded-full dark:border-dark-border text-xsm/[20px] text-slate-500 dark:text-slate-400">Creative UI</span>    
                            </div>
                        </div>
                        <div class="basis-full flex justify-center my-3 items-end">
                            <div class="drop-shadow-carousal-image-drop-shadow animate-ecommerce-animation bg-[length:100%_100%] w-64 h-36"></div>
                            <!-- <img src="assets/images/carousal_1.png" alt="" class="lg:w-full md:w-6/12 animate-image-fade opacity-100">
                            <img src="assets/images/allcourse.png" alt="" class="lg:w-[94%] top-0 left-0 animate-image-fade md:w-[48%] absolute md:translate-x-1/2 lg:translate-x-0"> -->
                    </div>
                    </div>
                </div>
            </ng-template> 
            
          </owl-carousel-o>
      </div>