import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {
  private _showMobileMenu$ = new BehaviorSubject<boolean>(false);
  public contactDetailsSubmitted = false;
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  get showMobileMenu$() {
    return this._showMobileMenu$.asObservable();
  }
  set showMobileMenu(value: boolean) {
    this._showMobileMenu$.next(value);
  }

  public scrollView(data: any) {
    const element = this.renderer.selectRootElement(`#${data}`, true);
    window.scrollTo({
      top: this.findPosition(element),
      behavior: 'smooth',
    });
  }

  private findPosition(obj: any): any {
    var currenttop = -120;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currenttop];
    }
  }
  ngOnDestroy(): void {
    this._showMobileMenu$.next(false);
  }
}
