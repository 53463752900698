<div class="mx-auto mt-8 px-6 md:px-8 lg:px-16 xl:px-30 2xl:px-55" id="initial">
    <div class="w-full">
        <h1
            class="text-3xl md:text-xLarge md:whitespace-nowrap leading-tight font-bold capitalize text-slate-700 [word-spacing:8px] dark:text-slate-200">
            We provide and build <br class="hidden sm:block" /><span class="text-sky-500 break-after-page">
                innovative solutions
            </span> for your <br />challenges and ideas
        </h1>
    </div>
    <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
        <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
        <span class="mt-1">Single point of a platform where you can solve your problems and build your ideas.</span>
    </div>
    <div class="text-xs/[16px] text-slate-500 dark:text-slate-400 flex items-start">
        <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
        <span class="mt-1">Together with your team, we will identify any room for improvement or automation.</span>
    </div>

    <div class="our_service_dtls">
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/responsive.svg" class="" class="w-8 h-8">
                                    </svg-icon>
                                </div>
                                Web & Mobile Apps
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="flex items-center justify-center p-3">
                                    <svg-icon src="assets/icons/svg/devops.svg" class="" class="w-8 h-8"> </svg-icon>
                                </div>
                                Dev-ops Automation
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/solution.svg" class="w-8 h-8"> </svg-icon>

                                </div>
                                Solution Providers
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/tech.svg" class="w-8 h-8"> </svg-icon>
                                </div>
                                Tech Consultants
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/ai_learn.svg" class="w-8 h-8"> </svg-icon>
                                </div>
                                AI/Machine Learning
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/automation.svg" class="w-8 h-8"> </svg-icon>
                                </div>
                                Testing automation
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/widgets.svg" class="w-8 h-8"> </svg-icon>
                                </div>
                                Widgets/plugin
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/database_admin.svg" class="w-8 h-8"> </svg-icon>
                                </div>
                                Databse admin
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/cloud.svg" class="w-8 h-8"> </svg-icon>
                                </div>
                                Cloud computing
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/cyber.svg" class="w-8 h-8"> </svg-icon>
                                </div>
                                Cyber security
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <div class="flex items-center flex-col w-full">
                        <div class="basis-full mb-2.5">
                            <div
                                class="flex-col w-[120px] border border-slate-300 rounded-lg dark:border-dark-border py-2.5 group capitalize flex items-center text-[8px] lg:leading-6 mb-4 font-semibold text-slate-700 dark:text-slate-200">
                                <div
                                    class="p-3 flex items-center justify-center">
                                    <svg-icon src="assets/icons/svg/training.svg" class="w-8 h-8"> </svg-icon>
                                </div>
                                Trainings/events
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    
    <app-carousal></app-carousal>
</div>