import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss'],
})
export class ModelComponent {
  @Input({ required: true }) showPopup: boolean = false;
  @Input({ required: false }) sendMail: boolean = false;
  @Input({ required: false }) contact: boolean = false;
  @Output() close = new EventEmitter<boolean>();

  public closePopup() {
    this.close.emit(false);
  }

}
