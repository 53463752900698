import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public year: number = new Date().getFullYear();

  constructor(public menuService:MenuService) { }

  ngOnInit(): void { }
}
