import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HeaderComponent } from './components/header/header.component';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { DescriptionComponent } from './components/description/description.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CarousalComponent } from './components/description/carousal/carousal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ArrowSVGComponent } from './components/arrow-svg/arrow-svg.component';
import { BusinessComponent } from './components/business/business.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProcessComponent } from './components/process/process.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { WorkComponent } from './components/work/work.component';
import { BackToTopComponent } from './components/back-to-top/back-to-top.component';
import { ContactComponent } from './components/contact/contact.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { ModelComponent } from './components/model/model.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SendDetailsBlockComponent } from './components/send-details-block/send-details-block.component';
import { ContactDetailsBlockComponent } from './components/contact-details-block/contact-details-block.component';
import { ProjectsDetailsComponent } from './components/projects-details/projects-details.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderMobileComponent,
    DescriptionComponent,
    CarousalComponent,
    ArrowSVGComponent,
    BusinessComponent,
    FooterComponent,
    ProcessComponent,
    ProjectsComponent,
    WorkComponent,
    BackToTopComponent,
    ContactComponent,
    TestimonialsComponent,
    ModelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    CarouselModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SendDetailsBlockComponent,
    ContactDetailsBlockComponent,
    ProjectsDetailsComponent
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
