import { Component } from '@angular/core';
import { ThemeService } from '../../services/theme-services.service';
import { MenuService } from 'src/app/services/menu-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

constructor(public themeService: ThemeService,public menuService: MenuService) { 
}


toggleTheme() {
  this.themeService.theme = !this.themeService.isDark ? 'dark' : 'light';
}

public toggleMobileMenu(): void {
  this.menuService.showMobileMenu = true;
}

}
