import { Component } from '@angular/core';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent {
   public showPopup:boolean = false;
   public sendingMail:boolean = false;
   public contact:boolean = false;

   
   public isSendingMail() {
     this.showPopup = true;
     this.sendingMail = true;
   }

   public isContacting() {
    this.showPopup = true;
    this.contact = true;
  }

   public closePopup(data:any) {
    this.showPopup = data;
    this.sendingMail = data;
    this.contact = data;
   }
}
