import { Component } from '@angular/core';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent {
  public showPopup:boolean = false;
  public sendingMail:boolean = false;

  public isSendingMail() {
    this.showPopup = true;
    this.sendingMail = true;
  }

  
  public closePopup(data:any) {
    this.showPopup = data;
    this.sendingMail = data;
   }
}
