import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CommonModule } from '@angular/common';

@Component({
  selector: 'projects-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './projects-details.component.html',
  styleUrls: ['./projects-details.component.scss']
})
export class ProjectsDetailsComponent {
  @Input({ required: false }) projectDetals:any= {};
  @Input({ required: true }) showPopup: boolean = false;
  @Output() close = new EventEmitter<boolean>();

  public closePopup() {
    this.close.emit(false);
  }
}
