import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent {
  customOptions: OwlOptions = {
    autoWidth:true,
    items:5,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    margin:5,
    navSpeed: 700,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items:2.3
      },
      400: {
        items: 4.6
      },
      550: {
        items:6
      },
      740: {
        items: 5.5
      },
      790: {
        items:6.3
      },
      940: {
        items: 9.2
      },
      1200: {
        items: 10.1
      }
    },
    nav: false
  }
}
