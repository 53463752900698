<div class="mx-auto mt-8 px-6 md:px-8 lg:px-16 xl:px-30 2xl:px-55" id="projects">
  <h1 class="text-3xl leading-tight font-bold uppercase text-slate-700 dark:text-slate-200">
    Our Latest
    <span class="text-sky-500">
      Projects
    </span>
  </h1>
  <ng-container *ngFor="let projects of projectsDetails | slice:start:end ; let i = index; let last = last">
    <div class="flex mt-8 flex-wrap justify-between items-start">
      <div class="lg:basis-1/3 basis-full">
        <div class="flex flex-col items-start">
          <a class="text-sm/[16px] font-bold text-slate-700 dark:text-slate-200 inline-flex items-baseline"
            href="/"><span
              class="bg-{{projects.color}}-600 uppercase text-white dark:text-slate-200 inline-flex w-8 h-8 items-center justify-center rounded text-xl">{{projects.nameTitle}}</span>{{projects.name}}
          </a>
          <span class="ml-10 text-{{projects.color}}-600 -mt-2 text-xsm font-semibold">{{projects.type}}</span>
        </div>

        <div class="mt-6">
          <div class="text-xs/[16px] flex items-start">
            <span class="mr-2"> <app-arrow-svg></app-arrow-svg></span>
            <span class="text-slate-700 dark:text-slate-200 mt-1">
              {{projects.smallDesc}}
            </span>
          </div>
          <div class="ml-7 mt-1.5 text-sm-11">
            <span class="text-slate-500 dark:text-slate-400">
              {{projects.description}}
            </span>
          </div>
          <div class="my-3 ml-7" *ngFor="let details of projects.detailedDesc.slice(0, 2);">
            <div class="mb-2">
              <h6 class="flex items-baseline whitespace-nowrap text-slate-700 dark:text-slate-200 -mt-px text-sm-11">
                <pre class="w-2 h-2 rounded-full bg-sky-500 mr-1"></pre>{{details.title}}
              </h6>
              <p class="text-xsm text-slate-500 dark:text-slate-400 ml-3">{{details.desc}}</p>
            </div>
          </div>
          <div class="w-full inline-flex items-center justify-center my-6 shadow-light_readmore_shadow dark:shadow-dark_readmore_shadow">
            <button
              class="text-sky-500 bg-transparent border hover:text-white focus:outline-none rounded-full text-xs px-8 py-2.5 text-center hover:bg-sky-500 border-sky-500 dark:text-sky-400 dark:hover:text-white focus:none"
              *ngIf="!projectDetailsLoading || itemIndex !== i" (click)="showProjectDetails(projects.detailedDesc,projects.color,projects.nameTitle,projects.name,projects.type)">Read more</button>
            <button *ngIf="projectDetailsLoading && itemIndex === i" type="button"
              class="inline-flex cursor-not-allowed items-center rounded-md bg-sky-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-primary-400"
              disabled="">
              <svg class="-ml-1 mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
              Loading...
            </button>
          </div>
        </div>
      </div>
      <div class="lg:basis-2/3 basis-full -m-1 flex flex-wrap justify-center lg:-m-2">
        <div class="flex lg:w-2/5 w-2/4 flex-wrap">
          <div class="w-1/2 p-1 md:p-2">
            <a href="assets/images/{{projects.smallImgSet[0].img}}" data-lightbox="roadtrip"
              data-title="{{projects.smallImgSet[0].title}}">
              <img alt="gallery"
                class="block h-full w-full rounded-lg object-cover object-center border-4 shadow shadow-3xl border-gray-200 dark:border-slate-50/[0.06]"
                src="assets/images/{{projects.smallImgSet[0].img}}" />
            </a>
          </div>
          <div class="w-1/2 p-1 md:p-2">
            <a href="assets/images/{{projects.smallImgSet[1].img}}" data-lightbox="roadtrip"
              data-title="{{projects.smallImgSet[1].title}}">
              <img alt="gallery"
                class="block h-full w-full rounded-lg object-cover object-center border-4 shadow shadow-3xl border-gray-200 dark:border-slate-50/[0.06]"
                src="assets/images/{{projects.smallImgSet[1].img}}" />
            </a>
          </div>
          <div class="w-full p-1 md:p-2">
            <a href="assets/images/{{projects.largeImgSet[0].img}}" data-lightbox="roadtrip"
              data-title="{{projects.largeImgSet[0].title}}">
              <img alt="gallery"
                class="block h-full w-full rounded-lg object-cover object-center border-4 shadow shadow-3xl border-gray-200 dark:border-slate-50/[0.06]"
                src="assets/images/{{projects.largeImgSet[0].img}}" />
            </a>
          </div>
        </div>
        <div class="flex lg:w-2/5 w-2/4 flex-wrap">
          <div class="w-full p-1 md:p-2">
            <a href="assets/images/{{projects.largeImgSet[1].img}}" data-lightbox="roadtrip"
              data-title="{{projects.largeImgSet[0].title}}">
              <img alt="gallery"
                class="block h-full w-full rounded-lg object-cover object-center border-4 shadow shadow-3xl border-gray-200 dark:border-slate-50/[0.06]"
                src="assets/images/{{projects.largeImgSet[1].img}}" />
            </a>
          </div>
          <div class="w-1/2 p-1 md:p-2">
            <a href="assets/images/{{projects.smallImgSet[2].img}}" data-lightbox="roadtrip"
              data-title="{{projects.smallImgSet[2].title}}">
              <img alt="gallery"
                class="block h-full w-full rounded-lg object-cover object-center border-4 shadow shadow-3xl border-gray-200 dark:border-slate-50/[0.06]"
                src="assets/images/{{projects.smallImgSet[2].img}}" />
            </a>
          </div>
          <div class="w-1/2 p-1 md:p-2">
            <a href="assets/images/{{projects.smallImgSet[3].img}}" data-lightbox="roadtrip"
              data-title="{{projects.smallImgSet[3].title}}">
              <img alt="gallery"
                class="block h-full w-full rounded-lg object-cover object-center border-4 shadow shadow-3xl border-gray-200 dark:border-slate-50/[0.06]"
                src="assets/images/{{projects.smallImgSet[3].img}}" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="my-12 w-full h-px dark:bg-slate-700 bg-slate-200" *ngIf="!last"></div>
  </ng-container>
  <div class="w-full inline-flex items-center justify-center mt-4"
    [ngClass]="{'shadow-light_viewmore_shadow dark:shadow-dark_viewmore_shadow': !show}">
    <button
      class="text-sky-500 bg-transparent border hover:text-white focus:outline-none rounded-full text-xs px-8 py-2.5 text-center hover:bg-sky-500 border-sky-500 dark:text-sky-400 dark:hover:text-white focus:none"
      *ngIf="!show && !loading" (click)="showLoader()">Show more</button>
    <button *ngIf="loading" type="button"
      class="inline-flex cursor-not-allowed items-center rounded-md bg-sky-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-primary-400"
      disabled="">
      <svg class="-ml-1 mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
        </path>
      </svg>
      Loading...
    </button>
  </div>
</div>


<projects-details [showPopup]="showPopup" [projectDetals]= "passingProjectItems" (close)="closePopup($event)"></projects-details>
