<div class="mx-auto mt-8 px-6 md:px-8 lg:px-16 xl:px-30 2xl:px-55">
  <h1 class="text-3xl leading-tight font-bold capitalize text-slate-700 dark:text-slate-200">
    Our <span class="text-sky-500">
      strategy 
    </span>  will help you <br /> achieve your <span class="text-sky-500">objectives</span>
  </h1>
    <div class="mt-8 flex flex-wrap">
      <div class="my-1 md:px-1 sm:w-full md:w-1/2 lg:w-1/3 inline-flex">
        <div class="p-3 pb-0 inline-flex items-stretch bg-pageBg dark:bg-dark-pageBg rounded shadow shadow-md hover:translate-y-sm hover:duration-700 sMobile:flex-col">
          <div class="w-16 mr-3 sMobile:inline-flex sMobile:mr-0 sMobile:w-full sMobile:justify-center">
            <div class="bg-sky-100 inline-flex items-center justify-center rounded-full w-12 h-12 dark:bg-dark-1">
              <img src="assets/icons/profit-up.png" alt="" class="w-6 h-6">
            </div>
          </div>
          <div class="flex flex-col mt-4 justify-between">
            <h6 class="text-sm/[16px] font-bold text-sky-500 sMobile:text-center sMobile:px-2.5">Grow your sales with better
              experience</h6>
            <div class="text-xmd my-3 text-slate-500 dark:text-slate-400 sMobile:px-2.5">
              We will guide you to expand your business and services with the help of the latest technologies. By connecting the dots
              across the globe you can explore more customers. As we are constantly working with new startups to top-level companies,
              we can provide innovative solutions which help to bring your business goals to the next level.
            </div>
            <div class="border-t border-gray-200 dark:border-slate-50/[0.06]">
              <div class="flex divide-x divide-gray-200 dark:divide-slate-50/[0.06]">
                <button
                  class="block flex-1 text-center text-sm text-indigo-400 hover:text-indigo-500 font-medium px-3 py-4"
                  (click)="isSendingMail()">
                  <div class="flex items-center justify-center">
                    <svg class="w-4 h-4 fill-current flex-shrink-0 mr-2" viewBox="0 0 16 16">
                      <path
                        d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                    </svg>
                    <span>Send Email</span>
                  </div>
                </button>
                <button
                  class="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4 group"
                  (click)="isContacting()"
                  >
                  <div class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 fill-current flex-shrink-0 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                    </svg>
                    <span>Contact Us</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-1 md:px-1 sm:w-full md:w-1/2 lg:w-1/3 inline-flex">
        <div class="p-3 pb-0 inline-flex items-stretch bg-pageBg dark:bg-dark-pageBg rounded shadow shadow-md hover:translate-y-sm hover:duration-700 sMobile:flex-col">
          <div class="w-16 mr-3 sMobile:inline-flex sMobile:mr-0 sMobile:w-full sMobile:justify-center">
            <div class="bg-sky-100 inline-flex items-center justify-center rounded-full w-12 h-12 dark:bg-dark-1">
              <img src="assets/icons/project-management.png" alt="" class="w-6 h-6">
            </div>
          </div>
          <div class="flex flex-col mt-4 justify-between">
            <h6 class="text-sm/[16px] font-bold text-sky-500 sMobile:text-center sMobile:px-2.5">Kick-start new ideas</h6>
            <div class="text-xmd my-3 text-slate-500 dark:text-slate-400 sMobile:px-2.5">
              To begin with, starting a business necessitates your time and a strong will to succeed. Most people believe that having
              a lot of money is necessary to start a business, but if you have a strong business concept and conduct your market
              research properly, you may succeed with little to no money. we will guide you to do the market research for your ideas
              and help to achieve your dream goals.
            </div>
            <div class="border-t border-gray-200 dark:border-slate-50/[0.06]">
              <div class="flex divide-x divide-gray-200 dark:divide-slate-50/[0.06]">
                <button
                  class="block flex-1 text-center text-sm text-indigo-400 hover:text-indigo-500 font-medium px-3 py-4"
                  (click)="isSendingMail()"
                  >
                  <div class="flex items-center justify-center">
                    <svg class="w-4 h-4 fill-current flex-shrink-0 mr-2" viewBox="0 0 16 16">
                      <path
                        d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                    </svg>
                    <span>Send Email</span>
                  </div>
                </button>
                <button
                  class="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4 group"
                  (click)="isContacting()"
                  >
                  <div class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 fill-current flex-shrink-0 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                    </svg>
                    <span>Contact Us</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-1 md:px-1 sm:w-full md:w-1/2 lg:w-1/3 inline-flex">
        <div class="p-3 pb-0 inline-flex items-stretch bg-pageBg dark:bg-dark-pageBg rounded shadow shadow-md hover:translate-y-sm hover:duration-700 sMobile:flex-col">
          <div class="w-16 mr-3 sMobile:inline-flex sMobile:mr-0 sMobile:w-full sMobile:justify-center">
            <div class="bg-sky-100 inline-flex items-center justify-center rounded-full w-12 h-12 dark:bg-dark-1">
              <img src="assets/icons/clipboard.png" alt="" class="w-6 h-6">
            </div>
          </div>
          <div class="flex flex-col mt-4 justify-between">
            <h6 class="text-sm/[16px] font-bold text-sky-500 sMobile:text-center sMobile:px-2.5">Upgrade your project</h6>
            <div class="text-xmd my-3 text-slate-500 dark:text-slate-400 sMobile:px-2.5">
              Since we are all in a competitive world we need to sharpen our weapons to face financial crises and unknown failures. We
              need to often offer new and improved features and speed enhancements to make the end-user experience better. We will
              constantly work with your team on ways to improve the software, giving greater efficiency to users.
            </div>
            <div class="border-t border-gray-200 dark:border-slate-50/[0.06]">
              <div class="flex divide-x divide-gray-200 dark:divide-slate-50/[0.06]">
                <button
                  class="block flex-1 text-center text-sm text-indigo-400 hover:text-indigo-500 font-medium px-3 py-4"
                  (click)="isSendingMail()"
                  >
                  <div class="flex items-center justify-center">
                    <svg class="w-4 h-4 fill-current flex-shrink-0 mr-2" viewBox="0 0 16 16">
                      <path
                        d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                    </svg>
                    <span>Send Email</span>
                  </div>
                </button>
                <button
                  class="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4 group"
                  (click)="isContacting()"
                  >
                  <div class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 fill-current flex-shrink-0 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                    </svg>
                    <span>Contact Us</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-1 md:px-1 sm:w-full md:w-1/2 lg:w-1/3 inline-flex">
        <div class="p-3 pb-0 inline-flex items-stretch bg-pageBg dark:bg-dark-pageBg rounded shadow shadow-md hover:translate-y-sm hover:duration-700 sMobile:flex-col">
          <div class="w-16 mr-3 sMobile:inline-flex sMobile:mr-0 sMobile:w-full sMobile:justify-center">
            <div class="bg-sky-100 inline-flex items-center justify-center rounded-full w-12 h-12 dark:bg-dark-1">
              <img src="assets/icons/problem.png" alt="" class="w-6 h-6">
            </div>
          </div>
          <div class="flex flex-col mt-4 justify-between">
            <h6 class="text-sm/[16px] font-bold text-sky-500 sMobile:text-center sMobile:px-2.5">Improve your skills</h6>
            <div class="text-xmd my-3 text-slate-500 dark:text-slate-400 sMobile:px-2.5">
              Knowledge is wealth. Upgrading your abilities is essential for career success since it allows you to remain relevant in
              an ever-changing employment market. By gaining new skills and information, you boost your worth as an employee and open
              up prospects for advancement and higher-level roles. We offer live events, interactive classes, and pre-recorded courses
              with real-time examples.
            </div>
            <div class="border-t border-gray-200 dark:border-slate-50/[0.06]">
              <div class="flex divide-x divide-gray-200 dark:divide-slate-50/[0.06]">
                <button
                  class="block flex-1 text-center text-sm text-indigo-400 hover:text-indigo-500 font-medium px-3 py-4"
                  (click)="isSendingMail()"
                  >
                  <div class="flex items-center justify-center">
                    <svg class="w-4 h-4 fill-current flex-shrink-0 mr-2" viewBox="0 0 16 16">
                      <path
                        d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                    </svg>
                    <span>Send Email</span>
                  </div>
                </button>
                <button
                  class="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4 group"
                  (click)="isContacting()"
                  >
                  <div class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 fill-current flex-shrink-0 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                    </svg>
                    <span>Contact Us</span>
                  </div>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="my-1 md:px-1 sm:w-full md:w-1/2 lg:w-1/3 inline-flex">
        <div class="p-3 pb-0 inline-flex items-stretch bg-pageBg dark:bg-dark-pageBg rounded shadow shadow-md hover:translate-y-sm hover:duration-700 sMobile:flex-col">
          <div class="w-16 mr-3 sMobile:inline-flex sMobile:mr-0 sMobile:w-full sMobile:justify-center">
            <div class="bg-sky-100 inline-flex items-center justify-center rounded-full w-12 h-12 dark:bg-dark-1">
              <img src="assets/icons/open-source.png" alt="" class="w-6 h-6">
            </div>
          </div>
          <div class="flex flex-col mt-4 justify-between">
            <h6 class="text-sm/[16px] font-bold text-sky-500 sMobile:text-center sMobile:px-2.5">Open source collaboration</h6>
            <div class="text-xmd my-3 text-slate-500 dark:text-slate-400 sMobile:px-2.5">
              Contributing to our open-source widgets allows you to work on real-world projects, learn new programming languages, hone
              your coding abilities, and receive hands-on experience in software development. You can interact with like-minded
              people, benefit from their knowledge, and develop meaningful relationships within the IT community by participating in
              these projects.
            </div>
            <div class="border-t border-gray-200 dark:border-slate-50/[0.06]">
              <div class="flex divide-x divide-gray-200 dark:divide-slate-50/[0.06]">
                <button
                  class="block flex-1 text-center text-sm text-indigo-400 hover:text-indigo-500 font-medium px-3 py-4"
                  (click)="isSendingMail()"
                  >
                  <div class="flex items-center justify-center">
                    <svg class="w-4 h-4 fill-current flex-shrink-0 mr-2" viewBox="0 0 16 16">
                      <path
                        d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                    </svg>
                    <span>Send Email</span>
                  </div>
                </button>
                <button
                  class="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4 group"
                  (click)="isContacting()">

                  <div class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 fill-current flex-shrink-0 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                    </svg>
                    <span>Contact Us</span>
                  </div>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="my-1 md:px-1 sm:w-full md:w-1/2 lg:w-1/3 inline-flex">
        <div class="p-3 pb-0 inline-flex items-stretch bg-pageBg dark:bg-dark-pageBg rounded shadow shadow-md hover:translate-y-sm hover:duration-700 sMobile:flex-col">
          <div class="w-16 mr-3 sMobile:inline-flex sMobile:mr-0 sMobile:w-full sMobile:justify-center">
            <div class="bg-sky-100 inline-flex items-center justify-center rounded-full w-12 h-12 dark:bg-dark-1">
              <img src="assets/icons/automation.png" alt="" class="w-6 h-6">
            </div>
          </div>
          <div class="flex flex-col mt-4 justify-between">
            <h6 class="text-sm/[16px] font-bold text-sky-500 sMobile:text-center sMobile:px-2.5">Automation and analytics</h6>
            <div class="text-xmd my-3 text-slate-500 dark:text-slate-400 sMobile:px-2.5">
              By collecting and analyzing data, you can gain valuable insights into how users interact with your project, identify
              areas for improvement, and make data-driven decisions to enhance its performance. Automation plays a significant role in
              the IT industry and offers several benefits that enhance efficiency, productivity, and overall operations.
            </div>
            <div class="border-t border-gray-200 dark:border-slate-50/[0.06]">
              <div class="flex divide-x divide-gray-200 dark:divide-slate-50/[0.06]">
                <button
                  class="block flex-1 text-center text-sm text-indigo-400 hover:text-indigo-500 font-medium px-3 py-4"
                  (click)="isSendingMail()"
                  >
                  <div class="flex items-center justify-center">
                    <svg class="w-4 h-4 fill-current flex-shrink-0 mr-2" viewBox="0 0 16 16">
                      <path
                        d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                    </svg>
                    <span>Send Email</span>
                  </div>
                </button>
                <button
                  class="block flex-1 text-center text-sm text-emerald-500 hover:text-emerald-600 font-medium px-3 py-4 group"
                  (click)="isContacting()">
                  
                  <div class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 fill-current flex-shrink-0 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                    </svg>
                    <span>Contact Us</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<app-model [showPopup]="showPopup" [sendMail]="sendingMail" [contact]="contact" (close)="closePopup($event)"></app-model>