<div class="bg-black z-50 fixed top-0 left-0 w-screen h-screen opacity-50"></div>
<div class="flex items-center justify-center fixed top-0 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-md max-h-full">
        <div class="relative bg-white rounded-lg shadow-modelShadow dark:bg-dark-1">
            <button type="button" (click)="closePopup(false)" class="z-50 absolute top-3 right-2.5 text-gray-400 bg-pageBg hover:text-sky-500 rounded-full text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:bg-dark-pageBg">
                <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="w-full rounded-lg  p-8  sm:p-12 border shadow-lg border-gray-200 dark:border-slate-50/[0.06]">
                <div class="flex flex-col items-center">
                    <div class="error-icon inline-flex">
                        <span class="error-x-mark">
                          <span class="error-x-mark-line left"></span>
                          <span class="error-x-mark-line right"></span>
                      </span>
                    </div>
                    <div class="overflow-auto font-medium text-xmd my-6 leading-5 text-center flex flex-col justify-center text-slate-500 dark:text-slate-400">
                      <p class="text-lg">Server down issue</p> 
                          We will resolved issue as soon as possible.
                      </div>
                      <div class="w-full inline-flex items-center justify-center">
                          <button (click)="closePopup(false)" class="bg-sky-500 border text-white border-primary w-1/5 rounded border p-3 hover:bg-sky-600 border-sky-500 dark:hover:text-white transition hover:bg-opacity-90"
                          >Ok</button>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>